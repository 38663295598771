<template>
  <div class="container" ref="container">
    <template v-if="isApplyOfferPage && !isChangePriceMode">
      <progress-bar :percent="stepProgress" />
    </template>

    <form @submit.prevent="() => {}">
      <page-header :title="'의뢰 지원하기'" titleSize="3rem" />

      <template v-if="step === 1">
        <template v-for="(block, blockIndex) in inputBlocks">
          <div :key="blockIndex">
            <div class="ui-border-line ui-h-0" :class="{'ui-mt-2': blockIndex > 0}" />
            <h4>{{ block.title }}</h4>

            <template v-if="block.type === 'select'">
              <div class="ui-border-line ui-h-0 ui-mb-1" />

              <tag-box>
                <li
                  v-for="(row, key) in block.options"
                  :key="key"
                  :class="{
                    on: isActiveTag(block, row),
                  }"
                  @click="onTagClick(block, row)"
                >
                  {{row}}
                </li>
              </tag-box>

              <div class="ui-border-line ui-h-1 ui-mt-1 ui-mb-2" style="height: 10px" />
            </template>
            <template v-else>
              <div class="input-box">
                <input
                  type="text"
                  v-model="formData[block.formDataKey]"
                  :placeholder="block.placeholder"
                  :required="block.required || false"
                  :maxlength="block.maxlength"
                  >
              </div>

              <div class="ui-border-line ui-h-1 ui-mt-3 ui-mb-2" style="height: 10px" />
            </template>
          </div>
        </template>

        <footer-box
          submitText="다음"
          :submitCb="() => nextStep()"
          :submitDisabled="!validate"
        >
        </footer-box>
      </template>

      <template v-if="step === 2">
        <div>
          <div class="ui-border-line ui-h-0"></div>
          <h4>제안 또는 전달할 내용을 입력하세요. <small>(선택사항)</small></h4>
          <p class="block-subtitle">의뢰에 맞는 상세한 제안 및 어필할 내용을 입력하세요.<br>* 개인 거래 유도 및 외부 링크 입력 불가 (정책 위반사항)</p>
          <div class="input-box">
            <textarea
              v-model="formData.bodyText"
              maxlength="300" 
              :placeholder="isSinger ? '추천 곡을 함께 작성해주세요!' : ''"
            />
            <!-- <textarea
              v-model="formData.bodyText"
              maxlength="300"
              :readonly="!isNewMembershipUser"
            /> -->
          </div>
          <p class="input-box-counter">{{formData.bodyText.length}} / 300</p>
        </div>

        <div class="ui-border-0 ui-mb-6"></div>

        <!-- <template v-if="!isNewMembershipUser">
          <div class="plus-membership-guide">
            <p>
              더 많은 홍보 내용과<br>
              제안 내용을 보내고 싶다면?
            </p>

            <button @click="() => $router.push('/new-membership')">
              플러스 멤버십 가입하기
            </button>
          </div>
        </template> -->

        <footer-box
          submitText="다음"
          :submitCb="() => nextStep()"
          :submitDisabled="!step2Validate"
        />
      </template>

      <template v-if="step === maxStep">
        <div>
          <div class="ui-border-line ui-h-0"></div>
          <h4>지원할 금액을 입력하세요 (출장비 포함)</h4>
          <p class="block-subtitle">지원 시 부가세가 포함된 금액으로 전달됩니다.<br>부가세란? 거래 시 최종 소비자가 부담하는 세금 (간접세)</p>
          <div class="input-box">
            <NumberInput
              :value="formData.price"
              @input="val => formData.price = val"
              required
              />
              <button type="button" @click="() => calcFakeData()">
                예상 수익 계산
              </button>
          </div>

          <template v-if="fakeData.revenue">
            <div class="ui-border-line ui-h-0 ui-mt-2 ui-mb-2"></div>
            <p style="color: #5a5a5a">
              <span>헤이비글 수수료</span>
              <span class="float-right">-{{$lib.addComma(fakeData.heybeagleFee || 0)}}원</span>
            </p>
            <div class="ui-border-line ui-h-0 ui-mt-2 ui-mb-2" style="height:1px"></div>
            <p class="color-lavender font-weight-bold">
              <span>예상 수익 금액</span>
              <span class="float-right">{{$lib.addComma(fakeData.revenue || 0)}}원</span>
            </p>
            <div class="ui-border-line ui-h-0 ui-mt-2 ui-mb-2" style="height:1px"></div>
            <p>
              <span>* 개인의 경우 원천세(3.3%) 공제 후 지급</span>
            </p>
          </template>
          <div class="ui-border-line ui-h-1 ui-mt-2 ui-mb-2" style="height: 10px"></div>
        </div>

        <div>
          <div class="ui-border-line ui-h-0 ui-mt-2"></div>
          <h4>꼭 읽어보세요!</h4>
          <p class="ui-p-indent-2" :style="{ color: '#5A5A5A' }">
            1. 용어 설명<br>
            - 부가세 : 거래 시 최종 소비자가 부담하는 세금<br>
            - 원천세 : 국세청에 납부하는 개인소득에 대한 세금<br>
            - 수수료 : 헤이비글 이용 수수료
          </p>
          <p class="ui-p-indent-2" :style="{ color: '#5A5A5A' }">2. 보낸 견적은 수정할 수 없어요. 신중히 기재하세요!</p>
          <p class="ui-p-indent-2" :style="{ color: '#5A5A5A' }">3. 예상액이므로 실제 금액과 차이가 있을 수 있어요.</p>
          <p class="ui-p-indent-2" :style="{ color: '#5A5A5A' }">4. 헤이비글 수수료 및 지급에 대한 자세한 내용은<br><span class="color-lavender text-underline" @click="$router.push('/artist/my-profile/fee-information-and-agreement/2?mode=view')">수수료 안내</span>를 참고하세요.</p>
        </div>
        <div class="ui-border-0 ui-mb-6"></div>

        <template v-if="isChangePriceMode">
          <footer-box
            submitText="금액 수정하기"
            :submitCb="() => putEstimate()"
            :submitDisabled="!lastValidate"
          />
        </template>
        <template v-else>
          <footer-box
            submitText="다음"
            :submitCb="() => goPreview()"
            :submitDisabled="!lastValidate"
          />
        </template>
      </template>
    </form>

    <modal
      ref="etcModal"
      title="직접 입력해주세요"
      class="etc"
      confirm="등록"
      :confirmCb="onConfirmEtcModal"
      :hiddenCb="clearEtcModal"
    >
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-model="modalData.value" :placeholder="modalData.placeholder" :maxlength="modalData.maxlength">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import NumberInput from '@/components/common/NumberInput.vue'
import TagBox from '@/components/common/TagBox.vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import Modal from '@/components/common/Modal.vue'

import type { IBlock } from '@/flow/offer/apply-offer'
import { validMultipleGenre } from '@/lib/globalLib'

export default {
  name: 'ApplyOffer',
  components: {
    PageHeader,
    FooterBox,
    NumberInput,
    TagBox,
    ProgressBar,
    Modal,
  },
  data() {
    return {
      step: 1,
      offerData: {},
      formData: {
        price: null,
        // ext1: '',
        ext2: '',
        ext3: '',
        ext4: '',
        ext5: '',
        /** 제공 서비스 */
        ext6: '',
        /** 참여인원 */
        ext7: '',
        /** 공연구성 */
        ext8: '',
        bodyText: '',
        artistidx: this.$route.query.artistidx,
      },
      artistidx: this.$route.query.artistidx,
      fakeData: {
        ext4: '',
        revenue: '',
        heybeagleFee: '',
      },
      // confirm: false,
      modalData: {
        value: '',
        callback: null,
        maxlength: undefined,
        placeholder: undefined,
      },
      events: {
        root: {},
      },
    }
  },
  computed: {
    validate() {
      return this.inputBlocks.every((block) => {
        const key = block.formDataKey
        const value = this.formData[key]

        if (block.required && !value) {
          return false
        }

        return true
      })
    },
    step2Validate() {
      return true
    },
    lastValidate() {
      let validate = true
      if (!this.formData.price) validate = false
      if (!this.fakeData.revenue) validate = false
      return validate
    },
    // isNewMembershipUser() {
    //   return this.$store.getters.isNewMembershipUser
    // },
    maxStep() {
      // return this.isNewMembershipUser ? 3 : 2
      return 3
    },
    stepProgress() {
      return Math.floor(this.step / this.maxStep * 100)
    },
    isChangePriceMode() {
      return this.$route.query.mode === 'change-price'
    },
    isApplyOfferPage() {
      return this.$route.name === 'ApplyOffer'
    },
    inputBlocks() {
      const offerData = this.offerData || {}
      const genre = String(offerData?.genre || '')
      const eventType = String(offerData?.eventType || '')

      const isWedingType = new Set(['결혼식', '주례없는 결혼식']).has(eventType)

      const blocks: IBlock[] = []

      /**
       * 개발환경에서 디버킹 편의를 위해 사용
       */
      const append = (args: IBlock) => {
        if (process.env.NODE_ENV !== 'production' && blocks.some((val) => val.formDataKey === args.formDataKey)) {
          throw new Error(`중복된 키 사용, ${args.formDataKey}`)
          // debugger
        }

        let multiple: boolean | undefined
        let separator: string | undefined

        if (args.type === 'select') {
          multiple =  args.multiple ?? false
          separator = args.separator ?? ','
        }

        blocks.push({
          ...args,
          multiple,
          separator,
        })
      }

      /*
       * 여기부터 block 추가
       */

      append({
        type: 'select',
        title: '동일 분야의 의뢰를 진행한 경험이 있나요?',
        required: true,
        formDataKey: 'ext2',
        options: ['처음입니다', '1~9회', '10회 이상', '50회 이상', '100회 이상'],
      })

    //   if (requiredKeys.includes('ext3')) {
    //     append({
    //       type: 'input',
    //       title: '추천하고 싶은 노래는?',
    //       required: true,
    //       formDataKey: 'ext3',
    //       placeholder: '예) 김동률-감사, 폴킴-너를 만나 등',
    //     })
    //   }

      if (validMultipleGenre(genre, '댄스', '연주', '퍼포먼스')) {
        append({
          type: 'input',
          title: '공연 구성을 간략하게 알려주세요.',
          required: true,
          formDataKey: 'ext8',
          placeholder: '예) 재즈 3중주, 국악+비보잉 공연 등',
          maxlength: 20,
        })
      }

      if (validMultipleGenre(genre, '노래', '댄스', '연주', '퍼포먼스')) {
        append({
          type: 'select',
          title: '행사에 참여하는 인원은 몇명인가요?',
          required: true,
          formDataKey: 'ext7',
          options: ['1명', '2명', '3명', '직접 입력'],
          etcOption: '직접 입력',
          etcMaxlength: 20,
        })
      }

      if (validMultipleGenre(genre, '노래')) {
        if (isWedingType) {
          append({
            type: 'select',
            title: '제공되는 파일 및 서비스를 알려주세요.',
            required: true,
            formDataKey: 'ext6',
            multiple: true,
            options: ['MR(엠알)', '지인으로 소개 가능'],
          })
        } else {
          append({
            type: 'select',
            title: 'MR은 누가 준비하나요?',
            required: true,
            formDataKey: 'ext5',
            options: ['전문가가 제공', '의뢰인이 준비'],
          })
        }
      }

      if (validMultipleGenre(genre, 'MC')) {
        append({
          type: 'select',
          title: '식순 및 대본 협의가 가능한가요?',
          required: true,
          formDataKey: 'ext5',
          options: ['가능합니다', '협의 불가'],
        })

        if (isWedingType) {
          append({
            type: 'select',
            title: '제공되는 파일 및 서비스를 알려주세요.',
            required: true,
            formDataKey: 'ext6',
            multiple: true,
            options: ['대본', '식순', '성혼선언문', '혼인서약서', '부모님 축사', '지인으로 소개 가능'],
          })
        }
      }

      append({
        type: 'select',
        title: '섭외 확정 후, 가능한 미팅 방식은?',
        required: true,
        formDataKey: 'ext4',
        multiple: true,
        options: ['온라인/전화', '오프라인'],
      })

      return blocks
    },


    isSinger() {
        return validMultipleGenre(this.offerData.genre, '노래');
    },
  },
  mounted() {
    this.events.root['ApplyOffer:postOffer'] = () => {
      this.postOffer()
    }

    for (const key in this.events.root) {
      if (this.events.root.hasOwnProperty(key)) {
        const cb = this.events.root[key]
        this.$root.$on(key, cb)
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.$store.commit('setGnbRightBtnCb', () => {
      //   vm.$router.push('/offer/calc/fees')
      // })
      
      if (vm.isChangePriceMode) {
        vm.formData.price = Number(vm.$route.query.proPrice)
        vm.step = vm.maxStep
      } else {
        vm.setGnbLeftBtnCb()
      }

      vm.getOffer()
      vm.artistidx=to.query.artistidx;
    })
  },
  beforeRouteLeave(to, from, next) {
    setTimeout(() => {
      if (!this.isChangePriceMode) {
        if (this.$store.state.history.back && this.step > 1) {
          next(false)
          this.step--
          return
        }
      }

      this.$store.commit('setGnbLeftBtnCb', undefined)

      const allowPaths = [
        /^\/offer\/[0-9]+\/apply/,
        /^\/artist\/my-offer\/preview\/.+/,
      ]

      if (allowPaths.every(reg => !reg.test(to.path))) {
        this.resetFormData()
      }

      next()
    })
  },
  methods: {
    goPreview() {
      if (!this.validate) return
      sessionStorage.setItem('previewEstimate', JSON.stringify({
        offerUid: this.offerData.offerUid,
        proPrice: this.formData.price,
        // proExt1: this.formData.ext1,
        proExt2: this.formData.ext2,
        // proExt3: this.formData.ext3,
        proExt4: this.formData.ext4,
        proExt5: this.formData.ext5,
        proExt6: this.formData.ext6,
        proExt7: this.formData.ext7,
        proExt8: this.formData.ext8,
        proBodyText: this.formData.bodyText,
      }))
      /** path: /artist/my-offer/:offerUid/:artistidx, name: AEProfile */
      this.$router.push({path: `/artist/my-offer/preview/${this.$route.query.artistidx}`} )
    },
    postOffer() {
      const historyStack = this.$store.state.history.stack
      const applyOfferIndex = historyStack.findIndex((stack) => this.$router.match?.(stack)?.name === 'ApplyOffer')

      if (!this.validate) return

      if (!this.offerData?.offerUid) {
        this.$toast('잘못된 의뢰정보입니다.')
        return
      }

      if(!this.artistidx) {
        this.$toast('프로필을 다시 선택해주세요.')
        return
      }

      let price = this.formData.price
      if (typeof price === 'string') {
        price = Number(price.replace(/,/g, ''))
      }
      if (isNaN(price)) {
        this.$toast('금액(숫자)만 입력해주세요.')
        return
      }
      if (!price) {
        this.$toast('금액을 확인해주세요.')
        return
      }

      // if (!this.confirm) {
      //   this.$store.commit('setModal', {
      //     show: true,
      //     title: '금액을 확인하세요!',
      //     body: `<span style="color: #000">${this.$lib.addComma(this.formData.price)}원\n(부가세 별도)\n</span><span style="color: #959595">으로 견적을 보내시겠어요?</span>`,
      //     confirm: '보내기',
      //     confirmCb: modal => {
      //       modal.hide()
      //       this.confirm = true
      //       this.postOffer()
      //     },
      //   })
      //   return
      // } else {
      //   this.confirm = false
      // }

      const req = {
        method: 'post',
        url: `/artist/offerPro/${this.offerData.offerUid}`,
        data: {
          price,
          // ext1: this.formData.ext1,
          ext2: this.formData.ext2,
        //   ext3: this.formData.ext3,
          ext4: this.formData.ext4,
          ext5: this.formData.ext5,
          ext6: this.formData.ext6,
          ext7: this.formData.ext7,
          ext8: this.formData.ext8,
          bodyText: this.formData.bodyText,
          artistidx: this.$route.params.artistidx,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (this.$lib.validNgWordCount(data, this.$store) === false) {
            return
          }

          if (resultData.success) {
            if (!Number(localStorage.getItem('successfulApplyOffer'))) {
              localStorage.setItem('successfulApplyOffer', '1')
            }
            this.$root.$emit('update:offerData', this.offerData.offerUid, {
              proPrice: price,
              recCount: this.offerData.recCount + 1,
            })
            this.$toast('지원서를 보냈습니다. 좋은 소식이 있을거예요!')
            this.resetFormData()
            if (applyOfferIndex === -1) {
              this.$router.push('/home')
            } else {
              this.$router.go(applyOfferIndex - historyStack.length)
            }
          } else if (resultData.code == -30) {
            this.$toast('지원자 정원이 다 찼습니다.')
            // TODO: isDev
            // this.$store.commit('setModal', {
            //   show: true,
            //   title: '지원자 정원이 다 찼습니다.',
            //   body: `<span style="color:#8E8E8E">좋은 조건의 의뢰는 몇분 내에\n마감되니 서둘러 지원해보세요.</span>

            //   <span style="color:#5A5A5A">지원자 제한없이, 원하는 시간에\n마음대로 지원하고 싶으세요?</span>
            //   <span style="color:#8E8E8E">그렇다면 멤버십 혜택을 알아보세요.</span>
            //   `,
            //   confirm: '멤버십 알아보기',
            //   confirmCb: modal => {
            //     this.$router.push('/membership', () => {
            //       modal.hide()
            //     })
            //   },
            //   cancel: '닫기',
            // })
          } else if (resultData.code == -31) {
            this.$toast('프로필이 승인되지 않아서 지원할 수 없습니다.')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    putEstimate() {
      if (!this.lastValidate) return

      if (!this.offerData?.offerUid) {
        this.$toast('잘못된 의뢰정보입니다.')
        return
      }

      let price = this.formData.price
      if (typeof price === 'string') {
        price = Number(price.replace(/,/g, ''))
      }
      if (isNaN(price)) {
        this.$toast('금액(숫자)만 입력해주세요.')
        return
      }
      if (!price) {
        this.$toast('금액을 확인해주세요.')
        return
      }

      const req = {
        method: 'put',
        url: `/artist/offerPro/${this.offerData.offerUid}`,
        data: {
          price,
        },
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (this.$lib.validNgWordCount(data, this.$store) === false) {
            return
          }

          if (resultData.success) {
            this.$toast('금액이 변경되었습니다.')
            this.resetFormData()
            this.$router.back()
          } else if (resultData.code == -31) {
            this.$toast('프로필이 승인되지 않아서 지원할 수 없습니다.')
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    scrollToTextarea(e) {
      this.$refs.container.scrollTop = e.target.offsetTop - window.innerHeight * 0.45
      this.$nextTick().then(() => {
        this.$refs.textarea.focus()
      })
    },
    resetFormData() {
      this.step = 1
      this.formData.price = null
      // this.formData.ext1 = ''
      this.formData.ext2 = ''
    //   this.formData.ext3 = ''
      this.formData.ext4 = ''
      this.formData.ext5 = ''
      this.formData.ext6 = ''
      this.formData.ext7 = ''
      this.formData.ext8 = ''

      this.fakeData.ext4 = ''
      this.fakeData.revenue = ''
      // this.confirm = false
    },
    getOffer() {
      const req = {
        method: 'get',
        url: `/client/offer/${this.$route.params.offerUid}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let offerData = data.response.offerData || {}

            if (!offerData.offerUid) {
              this.$router.back()
              return
            }

            this.offerData = offerData
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    nextStep() {
      this.step++
      if (this.step > this.maxStep) {
        this.step = this.maxStep
      }
      this.setGnbLeftBtnCb()
    },
    setGnbLeftBtnCb() {
      if (this.step <= 1) {
        this.$store.commit('setGnbLeftBtnCb', undefined)
        return
      }

      this.$store.commit('setGnbLeftBtnCb', () => {
        this.step--
        if (this.step <= 1) {
          this.step = 1
          this.$store.commit('setGnbLeftBtnCb', undefined)
        }
      })
    },
    calcFakeData() {
      this.fakeData.heybeagleFee = Math.floor(
        parseInt(this.formData.price) * (this.offerData.userReferrer
          ? this.$config.constant.fees.hbFeePerForUserReferrer
          : this.$config.constant.fees.hbFeePer
        ) / 100
      )
      this.fakeData.revenue = this.formData.price - this.fakeData.heybeagleFee
    },
    isActiveTag(block: IBlock, row: string) {
      const values = (this.formData[block.formDataKey] || '').split(block.separator).map((val) => val.trim()).filter((val) => !!val)
      const optionsSet = new Set(block.options)

      if (block.etcOption && block.etcOption === row) {
        const etcValue = values.find((val) => optionsSet.has(val) === false) || ''

        return !!etcValue
      }

      return values.includes(row)
    },
    onTagClick(block: IBlock, row: string) {
      const values = (this.formData[block.formDataKey] || '').split(block.separator).map((val) => val.trim()).filter((val) => !!val)
      const optionsSet = new Set(block.options)
      const setValue = (row) => {
        if (block.multiple) {
          const rowIndex = values.indexOf(row)
          const separator = /\s$/.test(block.separator) ? block.separator : `${block.separator} `

          if (rowIndex === -1) {
            values.push(row)
          } else {
            values.splice(rowIndex, 1)
          }

          this.formData[block.formDataKey] = values.join(separator)
          return
        }

        this.formData[block.formDataKey] = row
      }

      if (block.etcOption && block.etcOption === row) {
        const etcValue = values.find((val) => optionsSet.has(val) === false) || ''

        this.modalData.value = etcValue
        this.modalData.maxlength = block.etcMaxlength
        this.modalData.placeholder = block.etcPlaceholder
        this.modalData.callback = () => {
          const nextValue = String(this.modalData.value).trim()
          const prevIndex = values.indexOf(etcValue)
          const nextIndex = values.indexOf(nextValue)

          // 기존 값을 지움
          if (prevIndex !== -1) {
            values.splice(prevIndex, 1)
          }

          // 동일한 값이 없을 경우에만 추가
          if (nextIndex === -1) {
            setValue(nextValue)
          }
        }

        this.$refs.etcModal.show()
        return
      }

      setValue(row)
    },
    clearEtcModal() {
      this.modalData.value = ''
      this.modalData.callback = null
      this.modalData.maxlength = undefined
      this.modalData.placeholder = undefined
    },
    onConfirmEtcModal(modal) {
      this.modalData.callback?.()
      modal.hide()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

h4 {
  margin: 0 !important;
  font-weight: 500 !important;
  font-size: 2.5rem !important;
  line-height: 6.6rem !important;
}

.input-box {
  width: 100%;
  font-size: 2rem;
  line-height: 1.5;
  border: 1px solid #979797;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  margin-bottom: 4rem;
  input, textarea {
    padding: 0.7em 1em;
    border: 0;
    background: transparent;
    flex: 1;
  }
  button {
    padding: 1em;
    color: #fff;
    background: $color-deepLavender;
    font-weight: 500;
    font-size: 1.8rem;
  }
  textarea {
    height: 21rem;
  }
}

p {
  color: #979797;
  font-size: 1.9rem;

  &.block-subtitle {
    margin: -1rem 0 3rem;
    font-size: 1.9rem;
  }

  &.input-box-counter {
    font-size: 1.8rem;
    margin: 1.3rem 0 0;
    text-align: right;
  }
}

.plus-membership-guide {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);

  p {
    font-weight: 600;
    font-size: 29px;
    color: $color-white;
    text-align: center;
    margin-bottom: 1em;
  }

  button {
    font-weight: 500;
    font-size: 21px;
    color: $color-white;
    background-color: #2bc4cc;
    width: 325px;
    height: 70px;
    border-radius: 35px;
    margin: 0 auto;
  }
}
</style>
