var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    attrs: {
      type: "text",
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder
    },
    domProps: { value: _vm.computedValue },
    on: {
      input: function(e) {
        return _vm.onInput(e)
      },
      change: function(e) {
        return _vm.onChange(e)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }