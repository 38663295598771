<template>
  <input
    type="text"
    :value="computedValue"
    @input="e => onInput(e)"
    :readonly="readonly"
    :disabled="disabled"
    @change="e => onChange(e)"
    :placeholder="placeholder"
  >
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    placeholder :{
      type: String,
    },
    after: {
      type: String,
    },
  },
  computed: {
    computedValue() {
      let val = this.toNumber(this.value)
      if (val === 0) return ''
      return this.$lib.addComma(val) + (this.after ? this.after : '')
    }
  },
  methods: {
    onInput(e) {
      if (e.data && /[^0-9,.]/.test(e.data)) {
        e.preventDefault()
        this.$emit("input", this.toNumber(this.value))
      } else {
        let val = e.target.value || ''
        val = val.replace(/,/, '')
        val = val.replace(this.after, '')
        if (e.inputType === 'deleteContentBackward') {
          let afterLen = this.after ? this.after.length : 0
          let len = val.length - afterLen
          val = val.substr(0, len)
        }
        this.$emit("input", this.toNumber(val))
      }
      this.$forceUpdate()
    },
    onChange() {
      let val = this.toNumber(this.value)
      if (this.min > val) val = this.min
      this.$emit("input", val)
      this.$emit("change", val)
    },
    toNumber(value) {
      let val = 0
      if (typeof value === "number") return value
      if (typeof value === "string") {
        val = value.replace(/,/g, '')
        val = parseFloat(val)
        val = isNaN(val) ? 0 : val
      }
      if (this.max < val) return this.max
      return val
    }
  }
};
</script>