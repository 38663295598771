var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "container" },
    [
      _vm.isApplyOfferPage && !_vm.isChangePriceMode
        ? [_c("progress-bar", { attrs: { percent: _vm.stepProgress } })]
        : _vm._e(),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return (function() {})($event)
            }
          }
        },
        [
          _c("page-header", {
            attrs: { title: "의뢰 지원하기", titleSize: "3rem" }
          }),
          _vm.step === 1
            ? [
                _vm._l(_vm.inputBlocks, function(block, blockIndex) {
                  return [
                    _c(
                      "div",
                      { key: blockIndex },
                      [
                        _c("div", {
                          staticClass: "ui-border-line ui-h-0",
                          class: { "ui-mt-2": blockIndex > 0 }
                        }),
                        _c("h4", [_vm._v(_vm._s(block.title))]),
                        block.type === "select"
                          ? [
                              _c("div", {
                                staticClass: "ui-border-line ui-h-0 ui-mb-1"
                              }),
                              _c(
                                "tag-box",
                                _vm._l(block.options, function(row, key) {
                                  return _c(
                                    "li",
                                    {
                                      key: key,
                                      class: {
                                        on: _vm.isActiveTag(block, row)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.onTagClick(block, row)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(row) + " ")]
                                  )
                                }),
                                0
                              ),
                              _c("div", {
                                staticClass:
                                  "ui-border-line ui-h-1 ui-mt-1 ui-mb-2",
                                staticStyle: { height: "10px" }
                              })
                            ]
                          : [
                              _c("div", { staticClass: "input-box" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData[block.formDataKey],
                                      expression: "formData[block.formDataKey]"
                                    }
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: block.placeholder,
                                    required: block.required || false,
                                    maxlength: block.maxlength
                                  },
                                  domProps: {
                                    value: _vm.formData[block.formDataKey]
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.formData,
                                        block.formDataKey,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", {
                                staticClass:
                                  "ui-border-line ui-h-1 ui-mt-3 ui-mb-2",
                                staticStyle: { height: "10px" }
                              })
                            ]
                      ],
                      2
                    )
                  ]
                }),
                _c("footer-box", {
                  attrs: {
                    submitText: "다음",
                    submitCb: function() {
                      return _vm.nextStep()
                    },
                    submitDisabled: !_vm.validate
                  }
                })
              ]
            : _vm._e(),
          _vm.step === 2
            ? [
                _c("div", [
                  _c("div", { staticClass: "ui-border-line ui-h-0" }),
                  _vm._m(0),
                  _vm._m(1),
                  _c("div", { staticClass: "input-box" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formData.bodyText,
                          expression: "formData.bodyText"
                        }
                      ],
                      attrs: {
                        maxlength: "300",
                        placeholder: _vm.isSinger
                          ? "추천 곡을 함께 작성해주세요!"
                          : ""
                      },
                      domProps: { value: _vm.formData.bodyText },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formData,
                            "bodyText",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("p", { staticClass: "input-box-counter" }, [
                    _vm._v(_vm._s(_vm.formData.bodyText.length) + " / 300")
                  ])
                ]),
                _c("div", { staticClass: "ui-border-0 ui-mb-6" }),
                _c("footer-box", {
                  attrs: {
                    submitText: "다음",
                    submitCb: function() {
                      return _vm.nextStep()
                    },
                    submitDisabled: !_vm.step2Validate
                  }
                })
              ]
            : _vm._e(),
          _vm.step === _vm.maxStep
            ? [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "ui-border-line ui-h-0" }),
                    _c("h4", [
                      _vm._v("지원할 금액을 입력하세요 (출장비 포함)")
                    ]),
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "input-box" },
                      [
                        _c("NumberInput", {
                          attrs: { value: _vm.formData.price, required: "" },
                          on: {
                            input: function(val) {
                              return (_vm.formData.price = val)
                            }
                          }
                        }),
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function() {
                                return _vm.calcFakeData()
                              }
                            }
                          },
                          [_vm._v(" 예상 수익 계산 ")]
                        )
                      ],
                      1
                    ),
                    _vm.fakeData.revenue
                      ? [
                          _c("div", {
                            staticClass: "ui-border-line ui-h-0 ui-mt-2 ui-mb-2"
                          }),
                          _c("p", { staticStyle: { color: "#5a5a5a" } }, [
                            _c("span", [_vm._v("헤이비글 수수료")]),
                            _c("span", { staticClass: "float-right" }, [
                              _vm._v(
                                "-" +
                                  _vm._s(
                                    _vm.$lib.addComma(
                                      _vm.fakeData.heybeagleFee || 0
                                    )
                                  ) +
                                  "원"
                              )
                            ])
                          ]),
                          _c("div", {
                            staticClass:
                              "ui-border-line ui-h-0 ui-mt-2 ui-mb-2",
                            staticStyle: { height: "1px" }
                          }),
                          _c(
                            "p",
                            { staticClass: "color-lavender font-weight-bold" },
                            [
                              _c("span", [_vm._v("예상 수익 금액")]),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$lib.addComma(_vm.fakeData.revenue || 0)
                                  ) + "원"
                                )
                              ])
                            ]
                          ),
                          _c("div", {
                            staticClass:
                              "ui-border-line ui-h-0 ui-mt-2 ui-mb-2",
                            staticStyle: { height: "1px" }
                          }),
                          _vm._m(3)
                        ]
                      : _vm._e(),
                    _c("div", {
                      staticClass: "ui-border-line ui-h-1 ui-mt-2 ui-mb-2",
                      staticStyle: { height: "10px" }
                    })
                  ],
                  2
                ),
                _c("div", [
                  _c("div", { staticClass: "ui-border-line ui-h-0 ui-mt-2" }),
                  _c("h4", [_vm._v("꼭 읽어보세요!")]),
                  _c(
                    "p",
                    {
                      staticClass: "ui-p-indent-2",
                      style: { color: "#5A5A5A" }
                    },
                    [
                      _vm._v(" 1. 용어 설명"),
                      _c("br"),
                      _vm._v(" - 부가세 : 거래 시 최종 소비자가 부담하는 세금"),
                      _c("br"),
                      _vm._v(
                        " - 원천세 : 국세청에 납부하는 개인소득에 대한 세금"
                      ),
                      _c("br"),
                      _vm._v(" - 수수료 : 헤이비글 이용 수수료 ")
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "ui-p-indent-2",
                      style: { color: "#5A5A5A" }
                    },
                    [
                      _vm._v(
                        "2. 보낸 견적은 수정할 수 없어요. 신중히 기재하세요!"
                      )
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "ui-p-indent-2",
                      style: { color: "#5A5A5A" }
                    },
                    [
                      _vm._v(
                        "3. 예상액이므로 실제 금액과 차이가 있을 수 있어요."
                      )
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "ui-p-indent-2",
                      style: { color: "#5A5A5A" }
                    },
                    [
                      _vm._v("4. 헤이비글 수수료 및 지급에 대한 자세한 내용은"),
                      _c("br"),
                      _c(
                        "span",
                        {
                          staticClass: "color-lavender text-underline",
                          on: {
                            click: function($event) {
                              return _vm.$router.push(
                                "/artist/my-profile/fee-information-and-agreement/2?mode=view"
                              )
                            }
                          }
                        },
                        [_vm._v("수수료 안내")]
                      ),
                      _vm._v("를 참고하세요.")
                    ]
                  )
                ]),
                _c("div", { staticClass: "ui-border-0 ui-mb-6" }),
                _vm.isChangePriceMode
                  ? [
                      _c("footer-box", {
                        attrs: {
                          submitText: "금액 수정하기",
                          submitCb: function() {
                            return _vm.putEstimate()
                          },
                          submitDisabled: !_vm.lastValidate
                        }
                      })
                    ]
                  : [
                      _c("footer-box", {
                        attrs: {
                          submitText: "다음",
                          submitCb: function() {
                            return _vm.goPreview()
                          },
                          submitDisabled: !_vm.lastValidate
                        }
                      })
                    ]
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "modal",
        {
          ref: "etcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            confirm: "등록",
            confirmCb: _vm.onConfirmEtcModal,
            hiddenCb: _vm.clearEtcModal
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.value,
                    expression: "modalData.value"
                  }
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.modalData.placeholder,
                  maxlength: _vm.modalData.maxlength
                },
                domProps: { value: _vm.modalData.value },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.modalData, "value", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _vm._v("제안 또는 전달할 내용을 입력하세요. "),
      _c("small", [_vm._v("(선택사항)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "block-subtitle" }, [
      _vm._v("의뢰에 맞는 상세한 제안 및 어필할 내용을 입력하세요."),
      _c("br"),
      _vm._v("* 개인 거래 유도 및 외부 링크 입력 불가 (정책 위반사항)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "block-subtitle" }, [
      _vm._v("지원 시 부가세가 포함된 금액으로 전달됩니다."),
      _c("br"),
      _vm._v("부가세란? 거래 시 최종 소비자가 부담하는 세금 (간접세)")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("* 개인의 경우 원천세(3.3%) 공제 후 지급")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }